/* eslint-disable camelcase */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Fuse from 'fuse.js'
import { useQueryParamString } from 'react-use-query-param-string'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'

import SearchPostsGrid from '../../components/blog/SearchPostsGrid'

const Search = () => {
  const [query, setQuery, initialized] = useQueryParamString('query', '')

  const data = useStaticQuery(graphql`
    query PrismicPosts {
      allPrismicPost(
        sort: { fields: data___publish_date, order: DESC }
        filter: {
          data: {
            post_type: { eq: "Blog" }
            publish_date: { ne: null }
            hide_from_feeds: { eq: false }
            password: { eq: null }
          }
        }
      ) {
        totalCount
        edges {
          node {
            uid
            data {
              description
              publish_date
              hide_from_feeds
              password
              title {
                text
              }
              banner {
                alt
                url
                gatsbyImageData(width: 540)
              }
              categories {
                category {
                  document {
                    ... on PrismicCategory {
                      id
                      data {
                        name
                      }
                    }
                  }
                }
              }
              tags {
                tag {
                  document {
                    ... on PrismicTag {
                      id
                      data {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const fuse = new Fuse(data.allPrismicPost.edges, {
    keys: ['node.data.title.text'],
    threshold: 0.5,
    shouldSort: false,
  })

  const results = fuse.search(query)

  return (
    <Layout customSEO showHeader>
      <SEO
        title="Blog Search | AudioEye"
        pathname={typeof window !== 'undefined' ? window.location.pathname : null}
        desc="Search the latest stories about digital accessibility, WCAG / ADA website compliance, AudioEye, and more."
      />
      <SearchPostsGrid
        initialized={initialized}
        setQuery={setQuery}
        query={query}
        allPostsData={results.slice(0, 12)}
      />
    </Layout>
  )
}

export default Search
