/* eslint-disable indent */
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import idx from 'idx'

import Text from '../common/Text'
import Space from '../common/Space'
import PostGridItem from './PostGridItem'
import BlogSearch from '../common/BlogSearch'
import BackButton from '../common/BackButton'

const Wrapper = styled.div`
  position: relative;
`

export const GridWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  gap: 32px;

  .react-reveal {
    max-width: 500px;
  }

  @media (max-width: 1450px) {
    justify-content: space-evenly;
    justify-items: center;
  }

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    gap: 24px;
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  top: 0;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: #fafafa;

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const Header = styled.div`
  display: grid;
  gap: 48px;
  margin-bottom: 48px;

  @media (max-width: 1050px) {
    gap: 32px;
    margin-bottom: 32px;
  }
`

const TitleText = styled(Text)`
  @media (max-width: 1050px) {
    margin-top: 16px;
    text-align: center;
  }
`

const BoldText = styled(Text)`
  font-weight: 800 !important;
  display: inline;
`

const SearchPostsGrid = ({ initialized, setQuery, query, allPostsData }) => (
  <Wrapper>
    <Space height={80} mobileHeight={48} />
    <BackButton text="Back to blog" to="/blog/" />
    <Space height={80} mobileHeight={48} />
    {initialized && (
      <>
        <Header>
          <TitleText heading2>Search Results</TitleText>
          <BlogSearch setQuery={setQuery} query={query} />
          <Text>
            Showing top results for: <BoldText>{query}</BoldText>
          </Text>
        </Header>
        <GridWrapper role="region" id="searchResults" aria-live="polite">
          {allPostsData.length > 0 ? (
            allPostsData.map(({ item }, i) => (
              <Fragment key={item.node.data.title.text}>
                <PostGridItem
                  uid={item.node.uid}
                  publishDate={item.node.data.publish_date}
                  title={item.node.data.title.text}
                  index={i}
                  img={idx(item.node.data, _ => _.banner)}
                  categories={item.node?.data?.categories}
                  description={item.node.data?.description}
                />
              </Fragment>
            ))
          ) : (
            <Text largeBody>
              <BoldText>No results found</BoldText>
            </Text>
          )}
        </GridWrapper>
      </>
    )}
    <Space height={80} />
    <BG className="bg" />
  </Wrapper>
)

export default SearchPostsGrid
